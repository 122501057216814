/**
 * -------------------------------------------
 * mixin global untuk product / fn bisa dipakai
 * disemua product (ex. domain, hosting, vps, etc)
 * -------------------------------------------
 */
export const productMixin = {
  data() {
    return {
      list_product: {
        fields: [
          {
            key: "no",
            label: "",
            tdClass: "align-middle"
          },
          {
            key: "name",
            sortable: false,
            label: "Product or Services",
            tdClass: "align-middle"
          },
          {
            key: "price",
            label: "Price",
            sortable: false,
            tdClass: "align-middle"
          },
          {
            key: "duedate",
            label: "Due Date",
            sortable: false,
            tdClass: "align-middle text-center",
            thClass: "text-center"
          },
          {
            key: "status",
            label: "Status",
            sortable: false,
            thClass: "text-center"
          }
        ]
      }
    };
  },

  methods: {
    /**
     * ----------------------------------------
     * status color button action list product
     * ----------------------------------------
     */
    mx_status_color(status = "Active") {
      let color = "secondary";
      switch (status) {
        case "Active":
          color = "success";
          break;
        case "Pending":
          color = "warning";
          break;
        case "Suspended":
          color = "danger";
          break;
        case "Terminated":
          color = "light";
          break;
        case "Cancelled":
          color = "secondary";
          break;
      }
      return color;
    },

    /**
     * ----------------------------------------
     * gear color list product
     * ----------------------------------------
     */
    mx_gear_color(status = "Active") {
      let color = this.mx_status_color(status);
      return "kt-svg-icon kt-svg-icon--" + color;
    },

    /**
     * ----------------------------------------
     * domain name color
     * ----------------------------------------
     */
    mx_domain_color(status = "Active") {
      return "text-" + this.mx_status_color(status);
    }

    /**
     * ----------------------------------------
     *
     * ----------------------------------------
     */
    // mx_redirect(type = 'domain'){
    // 	type.toLowerCase();
    // }
  }
};

/**
 * ---------------------------------------------
 * digunakan untuk mixin domain
 * ---------------------------------------------
 */
import ApiService from "@/common/api.service";
import { ApiURL } from "@/common/mixins/general.js";

export const productDomainMixin = {
  name: "MixinProductDomain",
  mixins: [ApiURL],
  methods: {
    /**
     * -----------------------------------------
     * get status domain
     * -----------------------------------------
     */
    mx_status_domain(DomainID) {
      return new Promise((resolve, reject) => {
        this.xhr_domain("status", DomainID)
          .then(data => {
            resolve(data);
          })
          .catch(error => {
            reject(error);
          });
      });
    }
  }
};

/**
 * ---------------------------------------------
 * untuk product hosting mixin khusus nya
 * init 2020-12-24 @addin
 * ---------------------------------------------
 */
export const productHostingMixin = {
  mixins: [ApiURL],
  methods: {
    mx_hosting_status(hostingID) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.get(this.mxPath.status, hostingID)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response);
          });
      });
    }
  },

  computed: {
    /**
     * ------------------------------------
     * get url api from mixin
     * ------------------------------------
     */
    mxPath() {
      return this.api.product.hosting;
    }
  }
};
